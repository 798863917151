import React from 'react'
import Layout from '../components/Layout'

const MyBidsPage = () => {
  return (
    <Layout>
      MyBidsPage
    </Layout>
  )
}

export default MyBidsPage
